import React, {useState} from 'react';
import classes from "../../style.module.css";
import {getPriority, getStatus, getType} from "../../../../utils/globalFunctions";
import {Alert, Button} from "antd";
import {TaskDetails} from "../../../../types/types";
import TableRow from "./TableRow";
import ListTaskItem from "./ListTaskItem";
import AssignCompanyModal from "./modals/AssignModal/AssignCompanyModal";


interface ITaskColumnsProps {
    task: TaskDetails
    setIsModalOpen: (open: boolean) => void
}


const TableData: React.FC<ITaskColumnsProps> = ({task, setIsModalOpen}) => {
    const [error, setError] = useState("");

    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);

    return (
        <div className={classes.columnContainer}>
            <TableRow title={"Title"} data={task.title}/>
            <TableRow title={"Description"} data={task.text} className={classes.description}/>
            <TableRow title={"Status"} className={classes.tag} data={getStatus(task.status)}/>
            <TableRow title={"Priority"} data={getPriority(task.priority)} extraData={`(${task.priority})`}/>
            <TableRow title={"Type"} data={getType(task.taskKind)} extraData={`(${task.taskKind})`}/>
            <TableRow title={"Assigned users"}
                      data={<ListTaskItem setError={setError}
                                          setIsModalOpen={setIsModalOpen}
                                          taskId={task.id}
                                          data={task.users}
                                          type={"user"}/>}/>
            <TableRow title={"Assigned groups"}
                      data={<ListTaskItem setIsModalOpen={setIsModalOpen}
                                          setError={setError} taskId={task.id}
                                          data={task.groups}
                                          type={"group"}/>}/>
            <TableRow className={classes.company} title={"Company"} data={!task.companyName ? "Currently no assigned company" : task.companyName}
                      extraData={<Button onClick={() => {
                          setIsAssignModalOpen(true)
                      }}>Assign company</Button>}/>
            <AssignCompanyModal value={{id: task.companyId, name: task.companyName}} taskId={task.id}
                                isModalOpen={isAssignModalOpen}
                                setIsModalOpen={setIsAssignModalOpen}/>
            {error && <Alert type="error" className={classes.alert} message={error}/>}
        </div>
    );
};

export default TableData