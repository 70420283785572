import React, {useEffect, useState} from 'react';
import classes from "./styles.module.css";
import {Alert, Button, Card, Skeleton, Steps} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getWorkOrderDetails, updateWorkOrder} from "../../services/workOrders";
import {Product, WorkOrder} from "../../types/types";
import StepsFooter from "./components/StepsFooter";
import {assignProducts, getProductListById} from "../../services/products";
import StepChooser from "./steps/StepChooser";
import {stepTitles} from "../../utils/globalFunctions";
import {FormProduct} from "../../types/inputs";
import {v4 as uuidv4} from "uuid";

const WorkOrderEditPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [error, setError] = useState<string[]>([]);
    const [apiError, setApiError] = useState("");
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const [productInputs, setProductInputs] = useState<FormProduct[]>([]);


    const {data: workOrderDetails, isLoading} = useQuery({
        queryFn: () => getWorkOrderDetails(params.id),
        queryKey: ["workOrderDetails", params.id]  // Include params.id to make the query key unique per work order
    });

    const mutation = useMutation<WorkOrder, Error, { id: string, order_template: any }>({
        mutationFn: ({order_template}) => updateWorkOrder(workOrderDetails?.id, order_template),
        onSettled: () => {
            queryClient.invalidateQueries({queryKey: ['workOrderDetails', params.id]}).catch(console.error);
            queryClient.invalidateQueries({queryKey: ['workOrderHistory', params.id]}).catch(console.error);
            setError([]);
        },
        onError: (error) => {
            setApiError(error.message);
        }
    });

    const updateProductsMutation = useMutation<any, Error, { id: string, products: FormProduct[] }>({
        mutationFn: ({}) => assignProducts(workOrderDetails?.id, productInputs),
        onSettled: () => {
            queryClient.invalidateQueries({queryKey: ['workOrderDetails', params.id]}).catch(console.error);
            queryClient.invalidateQueries({queryKey: ['workOrderHistory', params.id]}).catch(console.error);
            setError([]);
        },
        onError: (error) => {
            setApiError(error.message);
        }
    });

    //TODO uncomment later
    //&& //(currentStep === 0 || currentStep === 2 || updateProductsMutation.status === "success"))

    useEffect(() => {
        if (mutation.status === "success") {
            if (currentStep === 3) {
                return navigate(`/work_orders/${params.id}`);
            }
            setCurrentStep(currentStep + 1);
        }
    }, [updateProductsMutation.status, mutation.status]);


    const productType = workOrderDetails?.order_template?.schemaContent?.materials?.find((item: any) => item.inputType === "productList");

    const {data: productList, isLoading: isLoadingProducts} = useQuery({
        queryFn: () => getProductListById(productType?.type),
        queryKey: ["productList", productType?.type],  // Include productType?.type in the queryKey
        enabled: !!productType?.type  // Only fetch when productType?.type is valid
    });

    const handleSelectProduct = (product: Product) => {

        setSelectedProduct({...product});

        const newInput: FormProduct = {
            productId: product.id,
            ...(product.serialNumber ? {serialNumber: ''} : {}),
            quantity: product.serialNumber ? "1" : '',
            itemNumber: uuidv4(),
            productName: product.name,
            description: ""
        };

        setProductInputs(prev => {
            return [...prev, {...newInput}];
        });
    };

    const getDropdownItems = (products: Product[] = []) => {

        return products.map((product, index) => ({
            key: String(index + 1),
            label: (
                <a
                    style={{textDecoration: 'none'}}
                    onClick={handleSelectProduct.bind(this, product)}
                >
                    {product.name}
                </a>
            ),
        }));
    };

    const dropdownItems = isLoadingProducts ? [] : getDropdownItems(productList);


    const inputValidation = () => {
        const errors: Set<string> = new Set(); // Initialize errors as a Set

        const schemaContent = workOrderDetails?.order_template?.schemaContent;

        // Ensure schemaContent exists and is an object
        if (schemaContent && typeof schemaContent === 'object') {
            // Iterate over each section in schemaContent
            Object.keys(schemaContent).forEach((sectionKey) => {
                const sectionItems = schemaContent[sectionKey];

                // Ensure each section is an array before iterating
                if (Array.isArray(sectionItems)) {
                    sectionItems.forEach((item: any) => {
                        if (item.isRequired && !item.value) {
                            const errorMessage = `${item.label} in ${sectionKey} is required but has no value.`;
                            errors.add(errorMessage); // Add error message to Set
                        }
                    });
                }
            });
        } else {
            // If schemaContent is not valid, push an appropriate error message
            errors.add("Invalid schema content structure.");
        }

        return Array.from(errors); // Convert Set back to an array
    };


    const productValidation = () => {
        const errors: Set<string> = new Set();  // Initialize an array to store errors

        // // Check if the productInputs array is empty
        if (productInputs.length === 0) {
            errors.add("Please add a product");
        }

        // Iterate over each product input to check individual fields
        productInputs.forEach((product) => {
            // Check if serialNumber exists and is empty
            if ('serialNumber' in product && !product.serialNumber) {
                errors.add(`Serial number is missing`);
            }

            // Check if quantity is less than 0
            //@ts-ignore
            if (product.quantity < 0 || !product.quantity) {
                errors.add(`Quantity is missing or less than 0`);
            }
        });

        return Array.from(errors)
    }


    const isValid = () => {

        let productErrors: string[] = [];
        let inputErrors: string[] = [];

        // if (currentStep === 1 || currentStep === 3) {
        //     productErrors = productValidation()
        // }

        if (currentStep === 3) {
            inputErrors = inputValidation()
        }

        const combinedErrors = [...productErrors, ...inputErrors];

        if (combinedErrors.length > 0) {
            setError(combinedErrors);
            return false
        }

        return true
    }


    console.log(productInputs)

    const handleSaveWorkOrder = async () => {

        const valid = isValid()

        if (!valid) {
            return
        }

        //TODO uncomment later

        // if (currentStep === 1 || currentStep === 3) {
        //     //UPDATE PRODUCTS
        //     updateProductsMutation.mutate({
        //         id: workOrderDetails?.id,
        //         products: productInputs,
        //     })
        // }

        mutation.mutate({
            id: workOrderDetails?.id,
            order_template: workOrderDetails?.order_template
        });

    };

    if (!workOrderDetails) return <h1>No work details</h1>;
    if (isLoading) return <Skeleton active={true}/>;

    const handleCancel = () => {
        navigate(`/work_orders/${params.id}`)
    }

    const stepChooserProps = {
        currentStep,
        selectedProduct,
        productInputs,
        setProductInputs,
        dropdownItems,
        workOrderDetails,
    }

    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <h2>Edit Work Order</h2>
                <Button onClick={handleCancel} danger>Cancel</Button>
            </div>
            <Steps
                size="small"
                current={currentStep}
                className={classes.steps}
                items={stepTitles}
            />
            <div className={classes.stepCard}>
                <br/>
                <Card style={{border: "2px solid #E6EFFC"}}>
                    <div className={classes.column}>
                        <StepChooser {...stepChooserProps}/>
                    </div>
                </Card>
                <br/>
                <StepsFooter currentStep={currentStep} setCurrentStep={setCurrentStep}
                             handleSaveWorkOrder={handleSaveWorkOrder}/>
                {error && error.map((e, idx) => <Alert key={idx} message={e} type={"error"}
                                                       className={classes.alert}/>)}
                {apiError && <Alert message={apiError} type={"error"} className={classes.alert}/>}
            </div>
        </div>
    );
};

export default WorkOrderEditPage;
