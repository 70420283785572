import React from 'react';
import ProductInputItem from "../../pages/work-orders/components/productItem/ProductInputItem";
import {Product} from "../../types/types";

import classes from "./style.module.css"
import {FormProduct} from "../../types/inputs";

interface ProductInputListProps {
    selectedProduct: Product | undefined;
    productInputs: FormProduct[] | undefined;
    setProductInputs: (productInputs: (prev: FormProduct[]) => FormProduct[]) => void
    handleRemoveProductInput: (product: FormProduct) => void
}

const ProductInputList: React.FC<ProductInputListProps> = ({
                                                               selectedProduct,
                                                               handleRemoveProductInput,
                                                               productInputs,
                                                               setProductInputs
                                                           }) => {

    const handleUpdateInput = (id: string, field: string, value: string | number) => {

        setProductInputs((prevInputs) =>
            prevInputs.map((input: FormProduct) =>
                input.itemNumber === id ? {...input, [field]: value} : input
            )
        );
    };


    return (
        <div className={classes.column}>
            {productInputs?.filter(pInput => !pInput.parentItemNumber)?.map((pInput) => {

                if(selectedProduct)
                return <ProductInputItem productInputs={productInputs} setProductInputs={setProductInputs}
                                         handleUpdateInput={handleUpdateInput} product={selectedProduct}
                                         key={pInput.itemNumber} productInput={pInput}
                                         handleRemoveProductInput={handleRemoveProductInput}/>
            })}
        </div>
    );
};

export default ProductInputList;