import React, { useState } from 'react';
import classes from "./styles.module.css";
import { Alert, Button, Card,  Skeleton, Steps } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getWorkOrderDetails, updateWorkOrder } from "../../services/workOrders";
import { Product, WorkOrder } from "../../types/types";
import StepsFooter from "./components/StepsFooter";
import DynamicInputRenderer from "./components/inputCreators/DynamicInputRenderer";
import ChooseProductInput from "../../components/inputs/ChooseProductInput";
import { getProductListById } from "../../services/products";
import {FormProduct} from "../../types/inputs";
import {v4 as uuidv4} from 'uuid';


const WorkOrderEditPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [error, setError] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [products, setProducts] = useState<Product[]>([]);
    const [formProducts, setFormProducts] = useState<FormProduct[]>([]);


    const { data: workOrderDetails, isLoading } = useQuery({
        queryFn: () => getWorkOrderDetails(params.id),
        queryKey: ["workOrderDetails", params.id]  // Include params.id to make the query key unique per work order
    });

    const mutation = useMutation<WorkOrder, Error, { id: string, order_template: any }>({
        mutationFn: ({ id, order_template }) => updateWorkOrder(workOrderDetails?.id, order_template),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['workOrderDetails', params.id] }).catch(console.error);
            queryClient.invalidateQueries({ queryKey: ['workOrderHistory', params.id] }).catch(console.error);
            setError([]);
        },
        onError: (error) => {
            //@ts-ignore
            setError(error.message);
        }
    });

    const productType = workOrderDetails?.order_template?.schemaContent?.materials?.find((item: any) => item.inputType === "productList");

    const { data: productList, isLoading: isLoadingProducts } = useQuery({
        queryFn: () => getProductListById(productType?.type),
        queryKey: ["productList", productType?.type],  // Include productType?.type in the queryKey
        enabled: !!productType?.type  // Only fetch when productType?.type is valid
    });

    const handleSelectProduct = (product: Product) => {
        const newFormProduct: FormProduct = {
            id: uuidv4(), // Generate a unique ID
            name: product.name,
            inputType: 'product',
            units: Number(product.units) || 0,
            serialNumber: product.serialNumber ? 1 : 0,
            install: 0, // Default value for install
            addons: [], // Initialize with an empty array
        };

        setFormProducts((prevFormProducts) => [...prevFormProducts, newFormProduct]);

        setProducts(prevProducts => [...prevProducts, product])

    };

    const getDropdownItems = (products: Product[] = []) => {
        return products.map((product, index) => ({
            key: String(index + 1),
            label: (
                <a
                    style={{ textDecoration: 'none' }}
                    onClick={handleSelectProduct.bind(this,product)}
                >
                    {product.name}
                </a>
            ),
        }));
    };

    const dropdownItems = isLoadingProducts ? [] : getDropdownItems(productList);

    const handleSaveWorkOrder = async () => {
        if (currentStep === 3) {
            let errors: string[] = [];  // Array to hold unique errors

            // Get the schemaContent object
            const schemaContent = workOrderDetails?.order_template?.schemaContent;

            // Ensure schemaContent exists and is an object
            if (schemaContent && typeof schemaContent === 'object') {
                // Iterate over each section in schemaContent
                Object.keys(schemaContent).forEach((sectionKey) => {
                    const sectionItems = schemaContent[sectionKey];

                    // Ensure each section is an array before iterating
                    if (Array.isArray(sectionItems)) {
                        sectionItems.forEach((item: any) => {
                            if (item.isRequired && !item.value) {
                                const errorMessage = `${item.label} in ${sectionKey} is required but has no value.`;
                                if (!errors.includes(errorMessage)) {
                                    errors.push(errorMessage);
                                }
                            }
                        });
                    }
                });
            } else {
                // If schemaContent is not valid, push an appropriate error message
                errors.push("Invalid schema content structure.");
            }

            // If there are errors, set them
            if (errors.length > 0) {
                //@ts-ignore
                setError(errors);  // Assuming setError can handle an array of errors
            } else {
                // No errors, proceed with mutation
                setError([]);
                await mutation.mutate({
                    id: workOrderDetails?.id,
                    order_template: workOrderDetails.order_template
                });
                navigate(`/work_orders/${params.id}`);
            }
        } else {
            // Proceed with mutation for other steps
            mutation.mutate({
                id: workOrderDetails?.id,
                order_template: workOrderDetails.order_template
            });
            setCurrentStep(currentStep + 1)
        }
    };

    if (!workOrderDetails) return <h1>No work details</h1>;
    if (isLoading) return <Skeleton active={true} />;
    const dateFormat = 'YYYY/MM/DD';

    const renderInputs = () => {
        switch (currentStep) {
            case 0:
                return (
                    <div className={classes.column}>
                        {workOrderDetails.order_template.schemaContent["heading"]?.map((item: any, index: number) => (
                            <DynamicInputRenderer item={item} key={item.id || `heading-${index}`} />
                        ))}
                    </div>
                );
            case 1:
                return (
                    <div className={classes.column}>
                        {workOrderDetails.order_template.schemaContent["materials"]?.map((item: any, index: number) => (
                            <DynamicInputRenderer item={item} key={item.id || `materials-${index}`} />
                        ))}
                        <ChooseProductInput setFormProducts={setFormProducts} formProducts={formProducts} products={products} dropdownItems={dropdownItems} setProducts={setProducts} />
                    </div>
                );
            case 2:
                return (
                    <div className={classes.column}>
                        {workOrderDetails.order_template.schemaContent["expenses"]?.map((item: any, index: number) => (
                            <DynamicInputRenderer item={item} key={item.id || `expenses-${index}`} />
                        ))}
                    </div>
                );
            case 3:
                return (
                    <div className={classes.column}>
                        <h1 style={{ margin: "0 auto" }}>Work order preview</h1>
                        {workOrderDetails.order_template.schemaContent["heading"]?.map((item: any, index: number) => (
                            <DynamicInputRenderer item={item} key={item.id || `heading-preview-${index}`} />
                        ))}
                        {workOrderDetails.order_template.schemaContent["materials"]?.map((item: any, index: number) => (
                            <DynamicInputRenderer item={item} key={item.id || `materials-preview-${index}`} />
                        ))}
                        <ChooseProductInput setFormProducts={setFormProducts} formProducts={formProducts} products={products} dropdownItems={dropdownItems} setProducts={setProducts} />
                        {workOrderDetails.order_template.schemaContent["expenses"]?.map((item: any, index: number) => (
                            <DynamicInputRenderer item={item} key={item.id || `expenses-preview-${index}`} />
                        ))}
                    </div>
                );
            default:
                return <></>;
        }
    }

    const handleCancel = () => {
        navigate(`/work_orders/${params.id}`)
    }

    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <h2>Edit Work Order</h2>
                <Button onClick={handleCancel} danger>Cancel</Button>
            </div>
            <Steps
                size="small"
                current={currentStep}
                className={classes.steps}
                items={[
                    {
                        title: 'Heading',
                    },
                    {
                        title: 'Materials',
                    },
                    {
                        title: 'Expenses',
                    },
                    {
                        title: 'Preview',
                    },
                ]}
            />
            <div className={classes.stepCard}>
                <br />
                <Card style={{ border: "2px solid #E6EFFC" }}>
                    <div className={classes.column}>
                        {renderInputs()}
                    </div>
                </Card>
                <br />
                {error && error.map((e, idx) => <Alert key={idx} message={e} type={"error"} className={classes.alert} />)}
                <StepsFooter currentStep={currentStep} setCurrentStep={setCurrentStep}
                             handleSaveWorkOrder={handleSaveWorkOrder} />
            </div>
        </div>
    );
};

export default WorkOrderEditPage;
