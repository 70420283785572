// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__Z\\+R8z {
    display:flex;
    flex-direction:row;
    justify-content:space-around;
    gap:10px;
    width:100%;
    height:auto;
    align-items:center;
}

.style_addonContainer__A7dSd {
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    gap:10px;
    width:100%;
    height:auto;
    align-items:center;
}


.style_cardBorder__iUJgV {
    border: 2px solid #E6EFFC;
}

.style_radioRow__GAx9Q {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 900px) {
    .style_container__Z\\+R8z {
        flex-direction: column;
        align-items: start;
    }
    .style_addonContainer__A7dSd {
        flex-direction: column;
        align-items: start;
    }
}

@media only screen and (max-width: 600px) {
    .style_container__Z\\+R8z {
        flex-direction: column;
        align-items: start;
    }
    .style_addonContainer__A7dSd {
        flex-direction: column;
        align-items: start;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/work-orders/components/productItem/style.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,4BAA4B;IAC5B,QAAQ;IACR,UAAU;IACV,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,wBAAwB;IACxB,QAAQ;IACR,UAAU;IACV,WAAW;IACX,kBAAkB;AACtB;;;AAGA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI;QACI,sBAAsB;QACtB,kBAAkB;IACtB;IACA;QACI,sBAAsB;QACtB,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,sBAAsB;QACtB,kBAAkB;IACtB;IACA;QACI,sBAAsB;QACtB,kBAAkB;IACtB;AACJ","sourcesContent":[".container {\n    display:flex;\n    flex-direction:row;\n    justify-content:space-around;\n    gap:10px;\n    width:100%;\n    height:auto;\n    align-items:center;\n}\n\n.addonContainer {\n    display:flex;\n    flex-direction:row;\n    justify-content:flex-end;\n    gap:10px;\n    width:100%;\n    height:auto;\n    align-items:center;\n}\n\n\n.cardBorder {\n    border: 2px solid #E6EFFC;\n}\n\n.radioRow {\n    display: flex;\n    flex-direction: row;\n}\n\n@media only screen and (max-width: 900px) {\n    .container {\n        flex-direction: column;\n        align-items: start;\n    }\n    .addonContainer {\n        flex-direction: column;\n        align-items: start;\n    }\n}\n\n@media only screen and (max-width: 600px) {\n    .container {\n        flex-direction: column;\n        align-items: start;\n    }\n    .addonContainer {\n        flex-direction: column;\n        align-items: start;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__Z+R8z`,
	"addonContainer": `style_addonContainer__A7dSd`,
	"cardBorder": `style_cardBorder__iUJgV`,
	"radioRow": `style_radioRow__GAx9Q`
};
export default ___CSS_LOADER_EXPORT___;
