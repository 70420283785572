import genericService from "./genericService";
import {Product, TemplateSchema} from "../types/types";
import {FormProduct} from "../types/inputs";

interface ProductsSchema {
    products: FormProduct[
],
    workOrderId: string
}

export const getProducts = async () => {
    return await genericService.genericGet<Product[]>("/product")
};

export const getProductList = async () => {
    return await genericService.genericGet<Product[]>("/product-list")
};

export const getProductListById = async (id: string) => {
    return await genericService.genericGet<Product[]>(`/product-list/${id}`)
};

export const getProductAddons = async (id:string) => {
    return await genericService.genericGet<Product[]>(`/product/${id}/addons`)
};

export const assignProducts = async (id:string, products: FormProduct[]) => {
    return await genericService.genericPut<ProductsSchema,any>(
        `/workorder_operations/product_installed`,
        {
            products:products,
            workOrderId: id
        }
    )
};