import React from 'react';
import classes from "../../styles.module.css";
import {Button, Card, Input} from "antd";
import Checkbox from "antd/es/checkbox/Checkbox";
import TextArea from "antd/es/input/TextArea";
import {FormTextarea} from "../../../../types/inputs";

interface TextareaCreatorProps {
    handleRemove: (remove: string) => void
    textarea: FormTextarea
    handleCheckboxChange: (id: string, checked: boolean) => void
    onInputChange: (id: string, field: string, value: string) => void
}

const TextareaCreator: React.FC<TextareaCreatorProps> = ({textarea, handleRemove, handleCheckboxChange, onInputChange}) => {

    return (
        <Card className={classes.inputCreatorCard}>
        <div className={classes.inputRow}>
            <h3>TextArea:</h3>
            <TextArea placeholder={"textarea"} autoSize/>
            <Input value={textarea.label} style={{width: "auto"}} placeholder={"label"}
                   onChange={(e) => onInputChange(textarea.id, 'label', e.target.value)}/>
            <Input value={textarea.name} style={{width: "auto"}} placeholder={"name"}
                   onChange={(e) => onInputChange(textarea.id, 'name', e.target.value)}/>
            <Checkbox checked={textarea.isRequired} onChange={(e)=>{handleCheckboxChange(textarea.id, e.target.checked)}}>Required</Checkbox>
            <Button danger onClick={handleRemove.bind(this, textarea.id)}>Remove</Button>
        </div>
        </Card>
    );
};

export default TextareaCreator;