import {Button, Dropdown, Input, Select, Tag} from "antd";
import React  from "react";
import {
    DoubleLeftOutlined,
    DoubleRightOutlined,
    ExclamationOutlined, PlusOutlined,
    ToolOutlined
} from "@ant-design/icons";
import {ITaskPriority, ITaskStatus, ITaskType, taskTypes} from "../types/types";
import TextArea from "antd/es/input/TextArea";
import {DatePicker} from "antd/lib";



const colorMarks = (color: string, times: number) => {
    const elements: React.ReactElement[] = [];

    for (let i = 0; i < times; i++) {
        elements.push(<ExclamationOutlined key={i} style={{ color: color,}} />);
    }

    return elements;
};


const TASK_STATUS: ITaskStatus = {
    inProgress: <Tag color="orange">IN PROGRESS</Tag>,
    beingDelivered: <Tag color="blue">BEING DELIVERED</Tag>,
    done: <Tag color="green">DONE</Tag>
}

const TASK_PRIORITY: ITaskPriority = {
    low: <>{colorMarks("#389E0D", 1)}</>,
    medium: <>{colorMarks("#ffcc00", 2)}</>,
    high: <>{colorMarks("red", 3)}</>
}

const TASK_TYPE: ITaskType = {
    installation: <DoubleLeftOutlined />,
    uninstallation: <DoubleRightOutlined />,
    maintenance: <ToolOutlined />
};

export const getStatus = (status: keyof ITaskStatus): React.ReactElement | undefined => {
    return TASK_STATUS[status];
};

export const getPriority = (priority: keyof ITaskPriority): React.ReactElement | undefined => {
    return TASK_PRIORITY[priority];
};


export const getType = (type: keyof ITaskType): React.ReactElement | undefined => {
    return TASK_TYPE[type];
};

export const formatDate = (date: string | undefined) => {
    const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    };

    //@ts-ignore
    return new Intl.DateTimeFormat('hr', options).format(new Date(date));
}

export function Highlighter(props: {
    searchWords: string[];
    autoEscape: boolean;
    textToHighlight: string;
    highlightStyle: { padding: number; backgroundColor: string }
}) {
    return null;
}


export const getTypeData = (type: taskTypes) => {
    switch (type) {
        case 0:
            return [
                {value: "inProgress", label: "in progress"},
                {value: "beingDelivered", label: "being delivered"},
                {value: "done", label: "done"},
            ]
        case 1:
            return [
                {value: "low", label: "low"},
                {value: "medium", label: "medium"},
                {value: "high", label: "high"},
            ]
        case 2:
            return [
                {value: "installation", label: "installation"},
                {value: "uninstallation", label: "uninstallation"},
                {value: "maintenance", label: "maintenance"},
            ]
        default:
            console.log("No correct type given")
    }
}



export const handleInputRender = (item: any, dropdownItems?: any) => {
    switch (item.inputType) {
        case "input":
            return <>
                <br/>
                <label style={{fontWeight: "bold", textTransform: "capitalize"}}>{item.label}</label>
                <Input suffix={item.suffix} maxLength={item.maxLength} type={item.type} name={item.name}/>
            </>
        case "textarea":
            return <>
                <br/>
                <label style={{fontWeight: "bold", textTransform: "capitalize"}}>{item.label}</label>
                <TextArea name={item.name} autoSize/>
            </>
        case "select":
            return <>
                <br/>
                <label style={{fontWeight: "bold", textTransform: "capitalize"}}>{item.label}</label>
                <br/>
                <Select style={{width:"100%"}} defaultValue={item.options[0]} options={item.options}/>
            </>
        case "date":
            return <>
                <br/>
                <label style={{fontWeight:"bold", textTransform:"capitalize"}}>{item.label}</label>
                <br/>
                <DatePicker style={{width:"100%"}} name={item.name}/>
            </>
        case "productList":
            return <>
                <h3>Choose a product</h3>
                <Dropdown menu={{items: dropdownItems}} placement="bottom">
                    <Button style={{width:"100%"}} icon={<PlusOutlined/>}>Choose a product</Button>
                </Dropdown>
                </>
        default: return null;
    }
}