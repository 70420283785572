import genericService from "./genericService";
import {Product} from "../types/types";

export const getProducts = async () => {
    return await genericService.genericGet<Product[]>("/product")
};

export const getProductList = async () => {
    return await genericService.genericGet<Product[]>("/product-list")
};

export const getProductListById = async (id: string) => {
    return await genericService.genericGet<Product[]>(`/product-list/${id}`)
};

export const getProductAddons = async (id:string) => {
    return await genericService.genericGet<Product[]>(`/product/${id}/addons`)
};

