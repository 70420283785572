import React from 'react';
import classes from "../styles.module.css";
import DynamicInputRenderer from "../components/inputCreators/DynamicInputRenderer";
import ChooseProductInput from "../../../components/inputs/ChooseProductInput";
import ProductInputList from "../../../components/inputs/ProductInputList";
import {FormProduct} from "../../../types/inputs";
import {Product} from "../../../types/types";

interface StepProps {
    workOrderDetails: any
    dropdownItems: any
    productInputs: FormProduct[]
    setProductInputs: (productInputs: (prev: FormProduct[]) => FormProduct[]) => void
    selectedProduct: Product | undefined;

}

const Step3:React.FC<StepProps> = ({workOrderDetails,dropdownItems, productInputs, setProductInputs, selectedProduct}) => {

    const handleRemoveProductInput = (product: FormProduct) => {
        setProductInputs((prevInputs) =>
            prevInputs.filter((input) => input.itemNumber !== product.itemNumber)
        );
    };

    return (
        <div className={classes.column}>
            <h1 style={{margin: "0 auto"}}>Work order preview</h1>
            {workOrderDetails.order_template.schemaContent["heading"]?.map((item: any, index: number) => (
                <DynamicInputRenderer item={item} key={item.id || `heading-preview-${index}`}/>
            ))}
            {workOrderDetails.order_template.schemaContent["materials"]?.map((item: any, index: number) => (
                <DynamicInputRenderer item={item} key={item.id || `materials-preview-${index}`}/>
            ))}
            <ChooseProductInput dropdownItems={dropdownItems} />
            <ProductInputList
                setProductInputs={setProductInputs}
                productInputs={productInputs}
                selectedProduct={selectedProduct}
                handleRemoveProductInput={handleRemoveProductInput}
            />
            {workOrderDetails.order_template.schemaContent["expenses"]?.map((item: any, index: number) => (
                <DynamicInputRenderer item={item} key={item.id || `expenses-preview-${index}`}/>
            ))}
        </div>
    );
};

export default Step3;
