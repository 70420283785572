import React from 'react';
import {Control, Controller, FieldErrors} from "react-hook-form";
import TextArea from "antd/es/input/TextArea";
import {Alert} from "antd";
import classes from "./style.module.css";
import {isEmpty} from "lodash";
import {TextAreaProps} from "antd/lib/input";
import {text} from "node:stream/consumers";

interface ICustomFormTextAreaProps {
    control: Control | any;
    label: string;
    name: string
    errors: FieldErrors | any
    textAreaProps?: TextAreaProps
    labelClassName?: string
}

const CustomFormTextArea: React.FC<ICustomFormTextAreaProps> = ({
                                                                    control,
                                                                    labelClassName,
                                                                    textAreaProps,
                                                                    label,
                                                                    name,
                                                                    errors
                                                                }) => {

    return (
        <>
            <label className={labelClassName ? labelClassName : ""}>{label}: </label>
            <Controller
                name={name}
                control={control}
                rules={{required: true}}
                render={({field}) => <TextArea {...textAreaProps} autoSize  {...field} />}
            />
            {!isEmpty(errors) && <Alert className={classes.fieldAlert} type={"error"} message={errors?.message}/>}
        </>
    );
};

export default CustomFormTextArea;