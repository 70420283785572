import React from 'react';
import {Product} from "../../../types/types";
import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import {FormProduct} from "../../../types/inputs";

interface Props {
    currentStep: number;
    selectedProduct: Product | undefined;
    dropdownItems: any
    workOrderDetails: any
    productInputs: FormProduct[]
    setProductInputs: (productInputs: (prev: FormProduct[]) => FormProduct[]) => void

}

const StepChooser: React.FC<Props> = ({currentStep, selectedProduct, dropdownItems, workOrderDetails, productInputs, setProductInputs}) => {
    if (currentStep === 0) {
        return <Step0 workOrderDetails={workOrderDetails}/>
    }

    if (currentStep === 1) {
        return <Step1 productInputs={productInputs} setProductInputs={setProductInputs}  selectedProduct={selectedProduct} dropdownItems={dropdownItems}
                      workOrderDetails={workOrderDetails}/>
    }
    if (currentStep === 2) {
        return <Step2 workOrderDetails={workOrderDetails}/>
    }
    if (currentStep === 3) {
        return <Step3 productInputs={productInputs} setProductInputs={setProductInputs}  selectedProduct={selectedProduct} workOrderDetails={workOrderDetails} dropdownItems={dropdownItems}/>
    }
    return (
        <></>
    );
};

export default StepChooser;


