import React from 'react';

interface IDetailsRowProps {
    label: string
    data: string | number | undefined | React.ReactElement
}

const DetailsRow:React.FC<IDetailsRowProps> = ({label,data}) => {
    return (
        <>
            <label style={{fontWeight: "bold", textTransform:"capitalize"}}>{label}: </label>
            <div>{data ? data : "Currently no data"}</div>
        </>
    );
};

export default DetailsRow;