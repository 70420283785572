import React, { useEffect, useState } from 'react';
import classes from "../styles.module.css";
import DynamicInputRenderer from "../components/inputCreators/DynamicInputRenderer";
import ChooseProductInput from "../../../components/inputs/ChooseProductInput";
import { Product } from "../../../types/types";
import ProductInputList from '../../../components/inputs/ProductInputList';
import { FormProduct } from "../../../types/inputs";

interface StepProps {
    workOrderDetails: any;
    selectedProduct: Product | undefined;
    dropdownItems: any;
    productInputs: FormProduct[]
    setProductInputs: (productInputs: (prev: FormProduct[]) => FormProduct[]) => void

}

const Step1: React.FC<StepProps> = ({ workOrderDetails, selectedProduct, dropdownItems, productInputs, setProductInputs }) => {

    const handleRemoveProductInput = (product: FormProduct) => {
        // setProductInputs(productInputs.filter(i => i.itemNumber !== product.itemNumber));

        setProductInputs((prevInputs) =>
            prevInputs.filter((input) => input.itemNumber !== product.itemNumber)
        );
    };

    return (
        <div className={classes.column}>
            {workOrderDetails.order_template.schemaContent["materials"]?.map((item: any, index: number) => (
                <DynamicInputRenderer item={item} key={item.id || `materials-${index}`} />
            ))}
            <ChooseProductInput dropdownItems={dropdownItems} />
            <ProductInputList
                setProductInputs={setProductInputs}
                productInputs={productInputs}
                selectedProduct={selectedProduct}
                handleRemoveProductInput={handleRemoveProductInput}
            />
        </div>
    );
};

export default Step1;
