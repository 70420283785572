import React, {useState} from 'react';
import {Button, Divider, Input, Radio, RadioChangeEvent} from "antd";
import classes from "./style.module.css";
import {Addon} from "../../../../types/types";

interface AddonProductProps {
    addon: Addon
    optionsWithDisabled: any
    handleRemove: (addon: Addon) => void;
}

const AddonProduct:React.FC<AddonProductProps> = ({addon, optionsWithDisabled, handleRemove}) => {

    const [value, setValue] = useState('1');

    const onChange = ({target: {value}}: RadioChangeEvent) => {
        setValue(value);
    };


    return (
        <div><Divider/>
            <div className={classes.addonContainer}>
                <h4 style={{width: "200px"}}>{addon.name}</h4>
                {addon.serialNumber && <Input type={"number"} placeholder={"serial number"} required/>}
                {addon.units && <Input type={"number"} placeholder={"quantity"} suffix={addon.units} required/>}
                <Radio.Group
                    options={optionsWithDisabled}
                    onChange={onChange}
                    value={value}
                    optionType="button"
                    buttonStyle="solid"
                    className={classes.radioRow}
                />
                <Button onClick={handleRemove.bind(this, addon)} danger>Remove</Button>
            </div>
        </div>
    );
};

export default AddonProduct;