import React from 'react';
import {Divider} from "antd";
import DetailsRow from "./DetailsRow";
import classes from "../styles.module.css"
import {isEmpty} from "lodash";

interface DetailsSectionProps {
    data: any
    section: string
}

const DetailsSection:React.FC<DetailsSectionProps> = ({data, section}) => {

    if(isEmpty(data)) {
      return <div>
           <h3 style={{color: "#4096FF"}}>{section}</h3>
            <p>Currently no data in this section</p>
       </div>
   }

    return (
        <div>
            <h3 style={{color: "#4096FF"}}>{section}</h3>
            <Divider/>
            <div className={classes.formContainer}>
            {data.map((headingItem: any, index: number) => (
                <DetailsRow key={headingItem.id + index} label={headingItem.label} data={headingItem.value}/>
            ))}
            </div>
        </div>
    );
};

export default DetailsSection;