import React from 'react';
import {Button, Card, Input} from "antd";
import classes from "../../styles.module.css";
import Checkbox from "antd/es/checkbox/Checkbox";
import {DatePicker, DatePickerProps} from "antd/lib";
import {FormDate} from "../../../../types/inputs";

interface DateCreatorProps {
    handleRemove: (remove: string) => void
    date: FormDate
    onInputChange: (id: string, field: string, value: string) => void
    handleCheckboxChange: (id: string, checked: boolean) => void
}


const DateCreator:React.FC<DateCreatorProps> = ({handleRemove,handleCheckboxChange,onInputChange, date}) => {

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
    };

    return (
        <Card className={classes.inputCreatorCard}>
            <div className={classes.inputRow}>
                <h3>DatePicker:</h3>
                <DatePicker style={{width:"300px"}} disabled onChange={onChange} />
                <Input
                    value={date.label}
                    style={{width: 'auto'}}
                    placeholder="Label"
                    onChange={(e) => onInputChange(date.id, 'label', e.target.value)}
                />
                <Input
                    value={date.name}
                    style={{width: 'auto'}}
                    placeholder="Name"
                    onChange={(e) => onInputChange(date.id, 'name', e.target.value)}
                />
                <Checkbox
                    checked={date.isRequired}
                    onChange={(e) => handleCheckboxChange(date.id, e.target.checked)}
                >
                    Required
                </Checkbox>
                <Button danger onClick={() => handleRemove(date.id)}>
                    Remove
                </Button>
            </div>
        </Card>
    );
};

export default DateCreator;