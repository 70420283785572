import React, {useState} from 'react';
import {Button, Divider, Input, Radio, RadioChangeEvent} from "antd";
import classes from "./style.module.css";
import {Addon} from "../../../../types/types";
import {FormProduct} from "../../../../types/inputs";
import TextArea from "antd/es/input/TextArea";

interface AddonProductProps {
    addon: Addon | undefined;
    input: FormProduct
    optionsWithDisabled: any
    handleRemoveAddon: (addon: FormProduct) => void;
    handleUpdateInput: (id: string, field: string, value: any) => void
}

const AddonProduct:React.FC<AddonProductProps> = ({addon, optionsWithDisabled, handleRemoveAddon, input, handleUpdateInput}) => {

    const [value, setValue] = useState('1');

    const onChange = ({target: {value}}: RadioChangeEvent) => {
        setValue(value);
        handleUpdateInput(input.itemNumber, "quantity", value)
    };

    if(!addon) return null

    return (
        <div><Divider/>
            <div className={classes.addonContainer}>
                <h4 style={{width: "200px"}}>{input.productName}</h4>
                {addon.serialNumber ?
                    <>
                        <Input style={{minWidth: "200px"}} type={"number"} placeholder={"serial number"}
                               onChange={(e) => handleUpdateInput(input.itemNumber, "serialNumber", e.target.value)}
                               required/>
                        <Radio.Group
                            options={optionsWithDisabled}
                            onChange={onChange}
                            value={value}
                            optionType="button"
                            buttonStyle="solid"
                            className={classes.radioRow}
                        />
                    </> :
                    <Input style={{minWidth: "200px"}} type={"number"} placeholder={"quantity"}
                           onChange={(e) => handleUpdateInput(input.itemNumber, "quantity", e.target.value)}
                           suffix={addon.units} required/>
                }
                <Button onClick={handleRemoveAddon.bind(this, input)} danger>Remove</Button>
            </div>
            <div className={classes.container}>
                <h3 style={{width: "185px"}}></h3>
                <TextArea onChange={(e) => handleUpdateInput(input.itemNumber, "description", e.target.value)}
                          placeholder={"description"} autoSize={true}/>
            </div>
        </div>
    );
};

export default AddonProduct;