import React, {useState} from 'react';
import {Alert, Button, Card} from "antd";
import classes from "../styles.module.css";
import {ArrowRightOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {WorkOrder} from "../../../types/types";
import WarningModal from "../../../components/modals/WarningModal/WarningModal";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {deleteWorkOrder} from "../../../services/workOrders";

interface IWorkItemProps {
    item: WorkOrder
}

const WorkOrderItem: React.FC<IWorkItemProps> = ({item}) => {

    const navigate = useNavigate();
    const queryClient = useQueryClient()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, setError] = useState("");

    const mutation = useMutation({
        mutationFn: ({id}: { id: string }) =>
            deleteWorkOrder(id),
        onSuccess: async () => {
            setIsModalOpen(false);
            setError("");
            await queryClient.invalidateQueries({queryKey: ['workOrders']}).catch(console.error)
        },
        onSettled: async () => {
            setIsModalOpen(false);
            setError("");
            await queryClient.invalidateQueries({queryKey: ['workOrders']}).catch(console.error)
        },
        onError: (error: Error) => {
            setError(error.message);
        },
    });

    const actionButtons = [
        <Button type={"text"} onClick={() => navigate(`/work_orders/${item.id}/edit`)}
                icon={<EditOutlined/>}>Edit</Button>,
        <Button type={"text"} onClick={() => {
            navigate(`/work_orders/${item.id}`)
        }}
                style={{color: "#4A9BFF"}} icon={<ArrowRightOutlined/>}>Details</Button>,
        <Button type={"text"} style={{color: "red"}} onClick={() => setIsModalOpen(true)}
                icon={<DeleteOutlined/>}>Delete</Button>
    ]

    const handleDeleteWorkOrder = (id: string) => {
        mutation.mutate({id});
    }

    return (
        <Card className={classes.wordOrderCard} actions={actionButtons}>
            <div style={{fontWeight: "bold", fontSize: "18px"}}>
                {item.title}
            </div>
            <WarningModal deleteText={"Delete"} title={"Are you sure you want to delete this work order?"}
                          handler={handleDeleteWorkOrder.bind(this, item.id)} isModalOpen={isModalOpen}
                          setIsModalOpen={setIsModalOpen} data={item.title}/>
            {error && <Alert message={error} type={"error"} className={classes.alert}/>}
        </Card>
    );
};

export default WorkOrderItem;