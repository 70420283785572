import React from "react";
import {Input, Select, DatePicker, Button, Dropdown} from "antd";
import TextArea from "antd/es/input/TextArea";
import classes from "../../styles.module.css"
import dayjs from "dayjs";
import {isUndefined} from "lodash";
import {GlobalOutlined, PlusOutlined} from "@ant-design/icons";
import ProductItem from "../productItem/ProductItem";

interface DynamicInputProps {
    item: {
        inputType: string;
        label: string;
        name: string;
        value: any;
        suffix?: React.ReactNode;
        maxLength?: number;
        type?: string;
        options?: Array<{ label: string; value: string | number }>;
    };
}

const DynamicInputRenderer: React.FC<DynamicInputProps> = ({ item }) => {
    const renderInput = () => {
        switch (item.inputType) {
            case "input":
                return (
                    <>
                        <label className={classes.labelStyle}>{item.label}</label>
                        <Input
                            suffix={item.suffix}
                            maxLength={item.maxLength}
                            defaultValue={item.value}
                            type={item.type}
                            name={item.name}
                            onChange={(e) => {
                                item.value = e.target.value;
                            }}
                        />
                    </>
                );
            case "textarea":
                return (
                    <>
                        <label  className={classes.labelStyle}>{item.label}</label>
                        <TextArea
                            name={item.name}
                            defaultValue={item.value}
                            onChange={(e) => {
                                item.value = e.target.value;
                            }}
                            autoSize
                        />
                    </>
                );
            case "select":
                return (
                    <>
                        <label  className={classes.labelStyle}>{item.label}</label>
                        <Select
                            style={{width:"100%"}}
                            placeholder="Select option"
                            defaultValue={item.value}
                            options={item.options}
                            onChange={(value) => {
                                item.value = value;
                            }}
                        />
                    </>
                );
            case "date":
                return (
                    <>
                        <label className={classes.labelStyle}>{item.label}</label>
                        <DatePicker
                            style={{width:"100%"}}
                            defaultValue={isUndefined(item.value) ? undefined : dayjs(item.value)}
                            name={item.name}
                            onChange={(date, dateString) => {
                                item.value = dateString;
                            }}
                        />
                    </>
                );
            case "location":
                return <>
                    <label className={classes.labelStyle}>{item.label}</label>
                    <Input defaultValue={item.value} name={item.name} onChange={(e) => {
                        item.value = e.target.value
                    }}/>
                    <Button className={classes.locationButton} icon={<GlobalOutlined/>}>Get
                        my location</Button>
                </>
            default:
                return null;
        }
    };

    return (
        <div style={{ marginBottom: 16 }}>
            {renderInput()}
        </div>
    );
};

export default DynamicInputRenderer;
