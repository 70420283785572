import React, {useState} from 'react';
import {Button, Card, Dropdown, Input, Radio, RadioChangeEvent} from "antd";
import classes from "./style.module.css"
import {PlusOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {getProductAddons} from "../../../../services/products";
import {Addon, Product} from "../../../../types/types";
import AddonProduct from "./AddonProduct";

interface IProductItemProps {
    product: Product
    handleRemoveProduct: (product: Product) => void
    handleUpdateInput: (property: string, value: string | number, id: string) => void
}

const ProductItem: React.FC<IProductItemProps> = ({product, handleRemoveProduct, handleUpdateInput}) => {

    const [value, setValue] = useState('1');
    const [productAddonsMap, setProductAddonsMap] = useState<{ [key: string]: Addon[] }>({});
    const addons = productAddonsMap[product.id] || [];

    const {data: productAddons, isLoading} = useQuery({
        queryFn: () => getProductAddons(product.id),
        queryKey: ["productAddons", product.id],
        //@ts-ignore
        onSuccess: (data: Product[]) => {
            setProductAddonsMap(prev => ({
                ...prev,
                [product.id]: prev[product.id] ? prev[product.id] : [] // Avoid overwriting existing addons
            }));
        }
    });

    const optionsWithDisabled = [
        {label: 'install', value: '1'},
        {label: 'uninstall', value: '-1'},
    ];

    const onChange = ({target: {value}}: RadioChangeEvent) => {
        setValue(value);
    };

    const getDropdownItems = (addons: Addon[] | undefined) => {
        return addons?.map((addon: Addon, index) => ({
            key: String(index + 1),
            label: (
                <a style={{textDecoration: 'none'}}
                   onClick={() => setProductAddonsMap(prev => ({
                       ...prev,
                       [product.id]: prev[product.id] ? [...prev[product.id], addon] : [addon] // Append the new addon
                   }))}>
                    {addon.name}
                </a>
            ),
        }));
    };

    const dropdownItems = isLoading ? [] : getDropdownItems(productAddons);

    const handleRemoveAddon = (name: Addon) => {
        setProductAddonsMap(prev => ({
            ...prev,
            [product.id]: prev[product.id].filter(a => a.id !== name.id)
        }));
    }

    return (
        <div>
            <Card className={classes.cardBorder}>
                <div className={classes.container}>
                    <h3 style={{width: "200px"}}>{product.name}</h3>
                    {product.serialNumber &&
                        <Input style={{minWidth: "200px"}} onChange={(e) => handleUpdateInput.bind(this,"serialNumber", e.target.value, product.id)} type={"number"} placeholder={"serial number"} required/>}
                    {product.units && <Input style={{minWidth: "200px"}} type={"number"} placeholder={"quantity"}
                                             suffix={product.units} required/>}
                    <Radio.Group
                        options={optionsWithDisabled}
                        onChange={onChange}
                        value={value}
                        optionType="button"
                        buttonStyle="solid"
                        className={classes.radioRow}
                    />
                    <Dropdown menu={{items: dropdownItems}} disabled={dropdownItems?.length === 0} placement="bottom">
                        <Button icon={<PlusOutlined/>}>Add addon</Button>
                    </Dropdown>
                    <Button onClick={handleRemoveProduct.bind(this, product)} danger>Remove</Button>
                </div>
                <div>
                    {addons.length !== 0 && <h3 style={{color: "#4096FF"}}><PlusOutlined/> Addons</h3>}
                    {addons.map((a) => {
                        return <AddonProduct key={a.id} addon={a} optionsWithDisabled={optionsWithDisabled}
                                             handleRemove={handleRemoveAddon}/>
                    })}
                </div>
            </Card>
        </div>
    );
};

export default ProductItem;
