// Define public routes accessible to all users
import {ProtectedRoute} from "./ProtectedRoute";
import {createBrowserRouter} from "react-router-dom";
import React from "react";
import LoginPage from "../pages/login/page";
import DashboardPage from "../pages/dashboard/page";
import TasksPage from "../pages/tasks/TasksPage";
import TaskDetailsPage from "../pages/tasks/TaskDetailsPage/TaskDetailsPage";
import PageNotFound from "../pages/not_found/PageNotFound";
import WikiPage from "../pages/wiki/WikiPage";
import InfoPage from "../pages/info/page";
import WorkOrdersPage from "../pages/work-orders/WorkOrdersPage";
import WorkOrderDetailsPage from "../pages/work-orders/WorkOrderDetailsPage";
import WorkOrderEditPage from "../pages/work-orders/WorkOrderEditPage";
import CreateWorkOrderTemplatePage from "../pages/work-orders/CreateWorkOrderTemplatePage";
import EditWorkOrderTemplatePage from "../pages/work-orders/EditWorkOrderTemplatePage";

const routesForPublic = [

    {
        path: "/login",
        element: <LoginPage/>,
    },
    {
        path: "*",
        element: <PageNotFound/>
    }
];

// Define routes accessible only to authenticated users
const routesForAuthenticatedOnly = [
    {
        path: "/",
        element: <ProtectedRoute/>, // Wrap the component in ProtectedRoute
        children: [
            {
                path: "/dashboard",
                element: <DashboardPage/>,
            },
            {
                path: "/tasks",
                element: <TasksPage/>,
            },
            {
                path: "/tasks/:id",
                element: <TaskDetailsPage/>,
            },
            {
                path: "/wiki",
                element: <WikiPage/>,
            },
            {
                path: "/work_orders",
                element: <WorkOrdersPage/>,
            },
            {
                path: "/work_orders/:id",
                element: <WorkOrderDetailsPage/>,
            },
            {
                path: "/work_orders/:id/edit",
                element: <WorkOrderEditPage/>,
            },
            // {
            //     path: `/work_orders/:id/create`,
            //     element: <CreateWorkOrderPage/>,
            // },
            {
                path: "/work_orders/template",
                element: <CreateWorkOrderTemplatePage/>,
            },
            {
                path: "/work_orders/template/:id",
                element: <EditWorkOrderTemplatePage/>,
            },
            {
                path: "/info",
                element: <InfoPage/>,
            }
        ],
    },
];


// Combine and conditionally include routes based on authentication status
const router = createBrowserRouter([
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
]);
export default router;