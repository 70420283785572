import React from 'react';
import {Divider} from "antd";
import DetailsRow from "./DetailsRow";
import classes from "../styles.module.css"
import {isEmpty} from "lodash";
import {useParams} from "react-router-dom";
import {log} from "node:util";

interface DetailsSectionProps {
    data: any
    section: string
}

const DetailsSection:React.FC<DetailsSectionProps> = ({data, section}) => {

    const params = useParams();

    // const {data: products , isLoading} = useQuery({
    //     queryFn: () => getProductInstalledByWorkOrder(params.id),
    //     queryKey: ["productsInstalled"]
    // })

    if(isEmpty(data)) {
      return <div>
           <h3 style={{color: "#4096FF"}}>{section}</h3>
            <p>Currently no data in this section</p>
       </div>
   }

    // if(isLoading) return <Spin/>

    return (
        <div>
            <h3 style={{color: "#4096FF"}}>{section}</h3>
            <Divider/>
            <div className={classes.formContainer}>
                {data
                    // .filter((headingItem: any) => headingItem.inputType !== "productList")
                    .map((headingItem: any, index: number) => (
                        <DetailsRow
                            key={headingItem.id + index}
                            label={headingItem.label}
                            data={headingItem.value}
                        />
                    ))
                }
            </div>
        </div>
    );
};

export default DetailsSection;