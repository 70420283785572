import React, {useState} from 'react';
import {Button, Card, CheckboxOptionType, Dropdown, Input, Radio, RadioChangeEvent, RadioGroupProps} from "antd";
import classes from "./style.module.css"
import {PlusOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {getProductAddons} from "../../../../services/products";
import {Addon, Product} from "../../../../types/types";
import AddonProduct from "./AddonProduct";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {FormProduct} from "../../../../types/inputs";
import {v4 as uuidv4} from "uuid";
import {isUndefined} from "lodash";
import TextArea from "antd/es/input/TextArea";

interface ProductInputItemProps extends RadioGroupProps {
    hasSerial: boolean
    units?: string,
    options: (string | number | CheckboxOptionType<CheckboxValueType>)[]
    handleUpdateInput: (id: string, field: string, value: any) => void
    id: string;
}

const ProductInputChooser: React.FC<ProductInputItemProps> = (props) => {

    const {
        hasSerial,
        units,
        options,
        handleUpdateInput,
        id,
        ...radioGroupProps
    } = props

    if (!hasSerial) {
        return <Input style={{minWidth: "200px"}} onChange={(e) => handleUpdateInput(id, "quantity", e.target.value)}
                      type={"number"} placeholder={"quantity"}
                      suffix={units} required/>
    }

    return <>
        <Input style={{minWidth: "200px"}} type={"number"}
               onChange={(e) => handleUpdateInput(id, "serialNumber", e.target.value)} placeholder={"serial number"}
               required/>
        <Radio.Group
            options={options}
            {...radioGroupProps}
            optionType="button"
            buttonStyle="solid"
            className={classes.radioRow}
        />
    </>
}

interface IProductItemProps {
    product: Product
    productInput: FormProduct
    handleRemoveProductInput: (product: FormProduct) => void
    handleUpdateInput: (id: string, field: string, value: any) => void
    setProductInputs: (productInputs: (prev: FormProduct[]) => FormProduct[]) => void
    productInputs: FormProduct[]
}

const ProductInputItem: React.FC<IProductItemProps> = (props) => {

    const {
        product,
        handleRemoveProductInput,
        productInput,
        handleUpdateInput,
        setProductInputs,
        productInputs
    } = props

    const [value, setValue] = useState('1');

    const [productAddonsMap, setProductAddonsMap] = useState<{ [key: string]: Addon[] }>({});
    const [productInputAddons, setProductInputAddons] = useState<FormProduct[]>();

    const [selectedAddon, setSelectedAddon] = useState<Addon>();

    const {data: productAddons, isLoading} = useQuery({
        queryFn: () => getProductAddons(productInput.productId),
        queryKey: ["productAddons", productInput.productId],

        //@ts-ignore
        onSuccess: () => {
            setProductAddonsMap(prev => ({
                ...prev,
                [product.id]: prev[product.id] ? prev[product.id] : [] // Avoid overwriting existing addons
            }));
        }
    });

    const optionsWithDisabled = [
        {label: 'install', value: '1'},
        {label: 'servis', value: '0'},
        {label: 'uninstall', value: '-1'},
    ];

    const onChange = ({target: {value}}: RadioChangeEvent) => {
        setValue(value);
        handleUpdateInput(productInput.itemNumber, "quantity", value)
    };


    const handleSelectProductAddon = (product: Product) => {

        setSelectedAddon({...product});

        const newInput: FormProduct = {
            productId: product.id,
            ...(product.serialNumber ? {serialNumber: ''} : {}),
            quantity: product.serialNumber ? "1" : '',
            itemNumber: uuidv4(),
            parentItemNumber: productInput.itemNumber,
            productName: product.name,
            description: ""
        };


        setProductInputs(prev => [...prev, {...newInput}]);

        setProductInputAddons(prev => {
            if (prev) {
                return [...prev, {...newInput}]
            } else {
                return [{...newInput}]
            }
        })
    };

    const getDropdownItems = (addons: Addon[] | undefined) => {
        return addons?.map((addon: Addon, index) => ({
            key: String(index + 1),
            label: (
                <a style={{textDecoration: 'none'}}
                   onClick={handleSelectProductAddon.bind(this, addon)}>
                    {addon.name}
                </a>
            ),
        }));
    };


    const dropdownItems = isLoading ? [] : getDropdownItems(productAddons);

    const handleRemoveAddon = (addon: FormProduct) => {

        setProductInputs(prev => prev.filter(i => i.itemNumber !== addon.itemNumber))

        setProductInputAddons((productInputAddons || []).filter(i => i.itemNumber !== addon.itemNumber))
    }

    return (
        <div>
            <Card className={classes.cardBorder}>
                <div className={classes.container}>
                    <h3 style={{width: "200px"}}>{productInput.productName}</h3>
                    <ProductInputChooser id={productInput.itemNumber} handleUpdateInput={handleUpdateInput}
                                         hasSerial={product.serialNumber!!} units={product.units} value={value}
                                         options={optionsWithDisabled} onChange={onChange}/>
                    <Dropdown menu={{items: dropdownItems}} disabled={dropdownItems?.length === 0} placement="bottom">
                        <Button icon={<PlusOutlined/>}>Add addon</Button>
                    </Dropdown>
                    <Button onClick={handleRemoveProductInput.bind(this, productInput)} danger>Remove</Button>
                </div>
                <div className={classes.container}>
                    <h3 style={{width: "135px"}}></h3>
                    <TextArea
                        onChange={(e: any) => handleUpdateInput(productInput.itemNumber, "description", e.target.value)}
                        placeholder={"description"} autoSize={true}/>
                </div>
                <div>
                    {isUndefined(productInputAddons) || productInputAddons?.length !== 0 &&
                        <h3 style={{color: "#4096FF"}}><PlusOutlined/>Addons</h3>}
                    {productInputAddons?.map((input: FormProduct) => {
                        return <AddonProduct handleUpdateInput={handleUpdateInput} key={input.itemNumber} input={input}
                                             addon={selectedAddon} optionsWithDisabled={optionsWithDisabled}
                                             handleRemoveAddon={handleRemoveAddon}/>
                    })}
                </div>
            </Card>
        </div>
    );
};

export default ProductInputItem;
