import React from 'react';
import {EditOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {Button, Card} from "antd";
import classes from "../styles.module.css";
import {formatDate} from "../../../utils/globalFunctions";
import {useNavigate, useParams} from "react-router-dom";
import DetailsSection from "./DetailsSection";


interface IDetailsCardProps {
    item: any
}

const DetailsCard: React.FC<IDetailsCardProps> = ({item}) => {

    const navigate = useNavigate();
    const params = useParams();

    return (
        <Card
            className={classes.titleCard}
            title={
                <h2>
                    Work order details <InfoCircleOutlined /> ({item.title})
                </h2>
            }
            extra={
                <Button
                    className={classes.editButton}
                    type="primary"
                    onClick={() => navigate(`/work_orders/${params.id}/edit`)}
                    icon={<EditOutlined />}
                >
                    Edit
                </Button>
            }
        >
            <div className={classes.workOrderContainer}>
                <div className={classes.formContainer}>
                    {Object.entries(item?.order_template?.schemaContent || {}).map(([key, value]) => (
                        <DetailsSection key={key} data={value} section={key.charAt(0).toUpperCase() + key.slice(1)}/>
                    ))}
                </div>
            </div>
            <div className={classes.date}>Created at: {formatDate(item?.createdDate)}</div>
            <div className={classes.date}>Last updated at: {formatDate(item?.updatedDate)}</div>
        </Card>
    );
};

export default DetailsCard;