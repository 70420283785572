import React, {useState} from 'react';
import {Button} from "antd";
import classes from "./styles.module.css";
import {PlusOutlined} from "@ant-design/icons";
import WorkOrderItem from "./components/WorkOrderItem";
import {useNavigate} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import WorkOrderSkeleton from "./components/WorkOrderSkeleton";
import {getMyWorkOrders} from "../../services/workOrders";
import TemplateModal from "./modals/TemplateModal";


const WorkOrdersPage = () => {

    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false);

    const {data: workOrders, isLoading} = useQuery({
        queryFn: () => getMyWorkOrders(),
        queryKey: ["workOrders"]
    })

    if (isLoading) return <WorkOrderSkeleton/>

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <h2>List of work orders</h2>
                <div className={classes.row}>
                    <Button onClick={() => {
                        navigate(`/work_orders/template`)
                    }} icon={<PlusOutlined/>}>Create new work order template</Button>
                    <Button type={"primary"} onClick={() => {
                        setIsModalOpen(true)
                    }} icon={<PlusOutlined/>}>Create new work order</Button>
                </div>
            </div>

            {/*<h2 style={{color: "#FABC3F", fontWeight: "bold"}}>Drafts</h2>*/}
            {/*<Divider style={{borderColor: '#FABC3F', borderWidth: 2}}/>*/}

            {/*<h2 style={{color: "#4096FF"}}>Finished</h2>*/}
            {/*<Divider style={{borderColor: '#4096FF', borderWidth: 2}}/>*/}

            <div className={classes.cardContainer}>
                {
                    workOrders && workOrders.length > 0 ?
                        workOrders.map((workOrder) => (
                            <WorkOrderItem key={workOrder.id} item={workOrder}/>))
                        : <div>Currently no work items</div>
                }
            </div>
            <TemplateModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
        </div>
    );
};

export default WorkOrdersPage;