import React from 'react';
import {Button, Dropdown} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import ProductItem from "../../pages/work-orders/components/productItem/ProductItem";
import {Product} from "../../types/types";
import {FormProduct} from "../../types/inputs";

interface ChooseProductInputProps {
    products: Product[];
    setProducts: (products: Product[]) => void
    dropdownItems:  { key: string, label: React.ReactNode }[] | undefined
    formProducts: FormProduct[]
    setFormProducts: (products: FormProduct[]) => void
}

const ChooseProductInput:React.FC<ChooseProductInputProps> = ({products, dropdownItems, setProducts, formProducts, setFormProducts}) => {


    const handleRemoveProduct = (product: Product) => {
        setProducts(products.filter(i => i.id !== product.id))
    }

    const handleUpdateInput = (property: string, value: string | number, id: string) => {

        // Find the index of the input with the given id
        const index = formProducts.findIndex(input => input.id === id);

        if (index === -1) {
            throw new Error('Input with the given id not found');
        }

        // Update the property with the new value
        formProducts[index] = {
            ...formProducts[index],
            [property]: value
        };


    }


    console.log(formProducts)

    return (
        <>
            <h3 style={{margin:"0"}}>Choose a product</h3>
            <Dropdown menu={{items: dropdownItems}} placement="bottom">
                <Button icon={<PlusOutlined/>}>Choose a product</Button>
            </Dropdown>
            {products.map((p, index) => {
                return <ProductItem handleUpdateInput={handleUpdateInput} key={p.id} product={p} handleRemoveProduct={handleRemoveProduct}/>
            })}
        </>
    );
};

export default ChooseProductInput;