import React, { useState, useEffect } from 'react';
import { Modal, Alert, Steps, Button } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import classes from '../styles.module.css';

import { handleInputRender } from "../../../utils/globalFunctions";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createTemplate, updateTemplate } from "../../../services/template";
import { getProductListById } from "../../../services/products";
import { Product } from "../../../types/types"; // Adjust import path

interface PreviewModalProps {
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    template: any;
    title: string;
    isEdit?: boolean;
}

interface FooterProps {
    currentStep: number,
    setCurrentStep: (currentStep: number) => void
    handleOk: () => void
    isEdit?: boolean
}

const Footer:React.FC<FooterProps> = ({currentStep,setCurrentStep,isEdit,handleOk}) => {
    return <div className={classes.stepButtonContainer}>
        {currentStep !== 0 && <Button onClick={() => setCurrentStep(currentStep - 1)}>Back</Button>}
        {currentStep < 2 &&
            <Button type="primary" onClick={() => setCurrentStep(currentStep + 1)}>Next</Button>}
        {currentStep === 2 &&
            <Button htmlType="submit" type="primary"
                    onClick={handleOk}>{isEdit ? "Update template" : "Create new template"}</Button>}
    </div>
}

const PreviewModal: React.FC<PreviewModalProps> = ({isModalOpen, setIsModalOpen, template, title, isEdit}) => {
    const [error, setError] = useState<string[]>([]);
    const navigate = useNavigate();
    const params = useParams();
    const [currentStep, setCurrentStep] = useState(0);
    const [chosenProducts, setChosenProducts] = useState<Product[]>([]);

    const productType = template?.materials?.find((item: any) => item.inputType === "productList");

    const {data: productList, isLoading } = useQuery({
        queryFn: () => getProductListById(productType?.type),
        queryKey: ["productList", productType?.type],
        enabled: !!productType, // Only fetch if productType exists
    });

    useEffect(() => {
        if (productList && !isLoading) {
            setChosenProducts([]);
        }
    }, [productList, isLoading]);

    const getDropdownItems = (products: Product[] | undefined) => {
        return products?.map((product, index) => ({
            key: String(index + 1),
            label: (
                <a
                    style={{ textDecoration: 'none' }}
                    onClick={() => setChosenProducts(prevProducts => [...prevProducts, product])}
                >
                    {product.name}
                </a>
            ),
        }));
    };

    const dropdownItems = isLoading ? [] : getDropdownItems(productList);

    const mutation = useMutation<any, Error, { title: string, schemaContent: any }>({
        mutationFn: () => createTemplate(title, template),
        onSettled: () => {
            setError([]);
            setIsModalOpen(false);
            navigate("/work_orders");
        },
        onError: (error: any) => {
            setError(error.message);
        }
    });

    const updateMutation = useMutation<any, Error, { id: string, title: string, schemaContent: any }>({
        mutationFn: ({ id, title, schemaContent }) => updateTemplate(id, title, schemaContent),
        onSettled: () => {
            setError([]);
            setIsModalOpen(false);
            navigate("/work_orders");
        },
        onError: (error: any) => {
            setError(error.message);
        }
    });

    const handleValidateTemplate = () => {
        let isValidated = true;
        const names = new Set();
        const errors = new Set(); // Use a Set to avoid duplicate errors

        if (title.trim() === "") {
            errors.add("Template title is required");
            isValidated = false;
        }

        const allItems = [...template.heading, ...template.materials, ...template.expenses];

        for (const item of allItems) {
            if (item.name.trim() === "") {
                errors.add("Name is required");
                isValidated = false;
            }
            if (hasWhiteSpace(item.name)) {
                errors.add("Name must be one word without spaces");
                isValidated = false;
            }
            if (item.label.trim() === "") {
                errors.add("Label is required");
                isValidated = false;
            }
            if (item.inputType === "select" && item.options.length === 0) {
                errors.add("Select input should have at least one option");
                isValidated = false;
            }
            if (item.inputType === "productList" && item.type === "") {
                errors.add("Please choose a product type");
                isValidated = false;
            }
            if (names.has(item.name)) {
                errors.add("Name must be unique for each input");
                isValidated = false;
            }
            names.add(item.name);
        }

        if (errors.size > 0) {
            //@ts-ignore
            setError(Array.from(errors)); // Convert Set to array before setting it as the error state
        }

        return isValidated;
    };


    const handleOk = () => {
        if (handleValidateTemplate()) {
            setIsModalOpen(false);
            if (isEdit) {
                updateMutation.mutate({
                    id: params.id ? params.id : "",
                    title: title,
                    schemaContent: template
                });
            } else {
                mutation.mutate({
                    title: title,
                    schemaContent: template
                });
            }
        } else {
            setIsModalOpen(true);
        }
    };

    function hasWhiteSpace(s: string) {
        return (/\s/).test(s);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        setError([]);
    };

    const renderInputs = () => {
        switch (currentStep) {
            case 0:
                return (
                    <div className={classes.column}>
                        {template["heading"].map((item: any) => (
                            <div key={item.id}>
                                {handleInputRender(item)}
                            </div>
                        ))}
                    </div>
                );
            case 1:
                return (
                    <div className={classes.column}>
                        {template["materials"].map((item: any) => (
                            <div key={item.id}>
                                {handleInputRender(item, item.inputType === "productList" ? dropdownItems : undefined)}
                            </div>
                        ))}
                    </div>
                );
            case 2:
                return (
                    <div className={classes.column}>
                        {template["expenses"].map((item: any) => (
                            <div key={item.id}>
                                {handleInputRender(item)}
                            </div>
                        ))}
                    </div>
                )
            default:
                return null;
        }
    }

    return (
        <Modal
            width={window.innerWidth <= 768 ? "90vw" : "50vw"}
            title="Preview template"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={<Footer currentStep={currentStep} setCurrentStep={setCurrentStep} isEdit={isEdit} handleOk={handleOk} />}
        >
            <h3>{title ? title : "No title"} </h3>
            <Steps
                size="small"
                current={currentStep}
                className={classes.steps}
                items={[
                    { title: 'Heading' },
                    { title: 'Materials' },
                    { title: 'Expenses' },
                ]}
            />
            <div className={classes.previewContainer}>
                {renderInputs()}
            </div>

            {error &&  error.map(e => <Alert message={e} type={"error"} className={classes.alert} />)}
        </Modal>
    );
};

export default PreviewModal;
