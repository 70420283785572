import genericService from "./genericService";
import {TaskLog, WorkOrder} from "../types/types";
import getAxiosInstance from "./axiosInstance";



interface IAssignRequestBody {
    groupsId: string,
    userIds: string
}

const client = getAxiosInstance();


export const getMyWorkOrders = async () => {
    return await genericService.genericGet<WorkOrder[]>("/workorder")
};

export const getWorkOrderDetails = async (id: string | undefined) => {
    return await genericService.genericGet<any>(`/workorder/${id}`)
};

export const getWorkOrderLogs = async (id: string | undefined) => {
    return await genericService.genericGet<TaskLog[]>(`/workorder/logs/${id}`)
};

export const getProductInstalledByWorkOrder = async (id: string | undefined) => {
    return await genericService.genericGet<any[]>(`/workorder/product_installed/${id}`)
};

export const updateWorkOrder = async (id: string, template: any) => {
    return await genericService.genericPut<any, any>(
        `/workorder/${id}`,
        {
            order_template: template
        }
    )
};

export const assignUsersGroupsWorkOrder = async (id: string | undefined, userIds: string[], groupsId: string[]) => {

    const reqBody: any = {}

    if (userIds.length > 0) {
        reqBody['userIds'] = userIds;
    }
    if (groupsId.length > 0) {
        reqBody['groupsId'] = groupsId;
    }

    return await genericService.genericPut<IAssignRequestBody, WorkOrder>(
        `/workorder/assign-users-groups/${id}`,
        reqBody
    )
};

export const deleteWorkOrder = async (id:string) => {

    try {
        const res = await client.delete(`/workorder/${id}`);
        return res.data

    } catch (error: string | any) {
        throw new Error(error.response.data.message);
    }
};

export const createWorkOrder = async (template: any) => {

    return await genericService.genericPost<any, any>(
        `/workorder`,
        {
            order_template: template
        }

    )
};






